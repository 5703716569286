body {
  margin: 0;
  padding: 0;
}

.img__hover:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

.hr-bottom {
  border-bottom-style: dotted;
  border-bottom-color: #aa0787;
  border-bottom-width: 5px;
  border-top: none;
  opacity: 1;
}

.bg-ite {
  background: #aa0787;
}

.bg-blanco {
  background: #ffffff;
}

.text-ite {
  color: #aa0787;
}

.text-blanco {
  color: #ffffff;
}

.w-60 {
  width: 60% !important;
}